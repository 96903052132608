import React from 'react'
import { AmountAttributeIndicatorProps } from './AmountAttributeIndicator.types'

const AmountAttributeIndicator = ({
  className,
  quantity,
  maxAmount = 5,
  height = '12px',
  fillColor = 'black'
}: AmountAttributeIndicatorProps) => {
  const ValueIndicator = ({ filled = false }: { filled: boolean }) => (
    <svg
      strokeWidth='1px'
      width={height}
      height={height}
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      {filled ? (
        <svg
          width='20'
          height='23'
          viewBox='0 0 20 23'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9.99342 22.758C4.89626 22.758 0.748413 18.6102 0.748413 13.513C0.748413 6.91907 8.72048 0.516602 9.99342 0.516602C11.2664 0.516602 19.2384 6.91907 19.2384 13.513C19.2384 18.6102 15.0906 22.758 9.99342 22.758Z'
            fill={fillColor}
          />
        </svg>
      ) : (
        <svg
          width='19'
          height='23'
          viewBox='0 0 19 23'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9.30434 22.3843C4.17482 22.3843 0 18.2095 0 13.08C0 6.44292 8.02331 0 9.30434 0C10.5854 0 18.6087 6.44292 18.6087 13.08C18.6087 18.2095 14.4339 22.3843 9.30434 22.3843ZM9.29894 0.809073C8.38469 0.892677 0.809073 6.86094 0.809073 13.08C0.809073 17.7645 4.61981 21.5753 9.30434 21.5753C13.9889 21.5753 17.7996 17.7645 17.7996 13.08C17.7996 6.86094 10.224 0.892677 9.29894 0.809073Z'
            fill={fillColor}
          />
        </svg>
      )}
    </svg>
  )

  return (
    <div
      className={`flex flex-row flex-nowrap items-center space-x-0 ${className}`}
    >
      {Array(maxAmount)
        .fill(0)
        .map((_, idx) => (
          <ValueIndicator key={idx} filled={idx < quantity} />
        ))}
    </div>
  )
}

export default AmountAttributeIndicator
