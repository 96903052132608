import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DynamicTag, Grid, transformRegionToMap, Button } from 'msp-components'
import { useNavigation } from 'msp-integrations'
import { CoffeeCategoryTile } from '@/components/molecules/CoffeeCategoryTile'
import { CoffeeCategoryGridProps } from './CoffeeCategoryGrid.types'

const CoffeeCategoryGrid = ({
  data,
  regions = [],
  custom
}: CoffeeCategoryGridProps) => {
  const styles = {
    headline: 'w-full text-center lg:px-24',
    subHeadline:
      'font-Roboto tracking-0 w-full pt-10 pb-24 lg:px-72 lg:text-center lg:font-medium'
  }
  const coffeeCategoryItems =
    transformRegionToMap(regions)?.get('coffeeCategoryTiles')?.components || []
  //Limit the Tile grid to 9 elements at the moment
  const coffeeCategoryTiles =
    coffeeCategoryItems.length > 9
      ? coffeeCategoryItems.splice(0, 8)
      : coffeeCategoryItems
  const showAllVaritiesButton = data?.showAllItemsButton || false
  let showAllVaritiesURL = ''
  if (showAllVaritiesButton) {
    showAllVaritiesURL = custom?.content?.url
  }
  const { formatMessage } = useIntl()
  const navigate = useNavigation()
  const [tileCSSClass, setTileCSSClass] = useState('')
  const backgroundColor = data?.backgroundColor || 'black'
  const fontColor =
    backgroundColor === 'grey' || backgroundColor === 'blue' ? 'black' : 'white'

  return (
    <div className={`cc-grid-main background-grid-${backgroundColor}`}>
      <DynamicTag
        className={`${styles.headline} text-${fontColor}`}
        componentTagName='Headline'
        tag='h1'
        colorScheme='primary'
      >
        {data.headline}
      </DynamicTag>
      <p className={`${styles.subHeadline} text-${fontColor} headline-h6`}>
        {data.subHeadline}
      </p>
      <Grid wrapperStyles='cc-grid-container'>
        {coffeeCategoryTiles.map((row: any, index: number) => (
          <CoffeeCategoryTile
            key={`cc-tile-${index}`}
            productId={row.data.productId}
            imageAlt={row.data.imageAlt}
            tileImage={row.custom?.content?.tileImage?.path}
            className={tileCSSClass}
            fontColor={fontColor}
            setGlobalTileCSSClass={setTileCSSClass}
          />
        ))}
      </Grid>
      {showAllVaritiesURL && showAllVaritiesButton && (
        <div
          className='cc-grid-button-wrapper'
          data-testid={'cc-grid-showAllBtn-wrapper'}
        >
          <Button
            className='cc-grid-showAllBtn'
            variant={'outline'}
            type={'primary'}
            iconName={'ArrowRightStick'}
            iconPosition='right'
            onClick={() => navigate(showAllVaritiesURL)}
          >
            {formatMessage({
              defaultMessage: 'Show all varieties',
              id: 'msp.contact_form.button.sendCoffee'
            })}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CoffeeCategoryGrid
